import axios from "axios";
import store from "@/store";
import { MessageBox, Loading } from "element-ui";
import commonConfig from "../config.js";

// create an axios instance
const service = axios.create({
    baseURL: commonConfig.baseURL, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        // loading = Loading.service();
        if (!store.state.isLoadingDisabled) {
            store.commit("setLoading", true);
        }
        // config.headers["X-Requested-With"] = "XMLHttpRequest";

        if (store.getters.getApiToken) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers["Authorization"] = store.getters.getApiToken;
        }
        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// token过期重放请求
const API_STATUS_UNAUTHORIZED = 401;
let isRefreshing = false;
let refreshSubscribers = [];
const subscribeTokenRefresh = (cb) => refreshSubscribers.push(cb);
const onRefreshed = (token) => {
    console.log("refreshing ", refreshSubscribers.length, " subscribers");
    refreshSubscribers.map((cb) => cb(token));
    refreshSubscribers = [];
};

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        // loading.close();
        store.commit("setLoading", false);
        store.commit("enableLoading", false);

        const res = response.data;
        // 全局错误处理
        if (!(response.status + "").startsWith(2)) {
            // 401未登录
            if (response.status === 401) {
                MessageBox.alert("未登录或登录信息过期，请重新登录", "温馨提示", {
                    confirmButtonText: "确定",
                    callback: () => {
                        location.href = "/";
                    },
                });
            } else {
                // 其他错误
                let msg = res.message || "Error";
                MessageBox.alert(msg, "出错啦", {
                    confirmButtonText: "确定",
                    callback: () => { },
                });
            }
            return Promise.reject(new Error(res.message || "Error"));
        } else {
            return res;
        }
    },
    (error) => {
        // loading.close();
        store.commit("setLoading", false);
        store.commit("enableLoading", false);

        const status = error.response ? error.response.status : false;
        const originalRequest = error.config;

        if (error.response) {
            if (status === API_STATUS_UNAUTHORIZED && store.state.token) { // token过期刷新token
                if (!isRefreshing) {
                    isRefreshing = true;
                    store
                        .dispatch("refreshToken")
                        .then((newToken) => {
                            isRefreshing = false;
                            onRefreshed(newToken);
                        })
                        .catch(() => {
                            isRefreshing = false;
                        });
                }
    
                return new Promise((resolve) => {
                    subscribeTokenRefresh((token) => {
                        // replace the expired token and retry
                        // originalRequest.headers["Authorization"] = "Bearer " + token;
                        // resolve(axios(originalRequest));
                        resolve(service(originalRequest));
                    });
                });
            } else if(status === API_STATUS_UNAUTHORIZED && !store.state.token) { // 未登录 去登录
                store.commit("logout");
                MessageBox.alert("未登录或登录信息过期，请重新登录", "温馨提示", {
                    confirmButtonText: "确定",
                    callback: () => {
                        location.href = "/";
                    },
                });
            } else { // other errors just tips!
                if (error.response.data.message) {
                    MessageBox.alert(error.response.data.message, "出错啦", {
                        confirmButtonText: "确定",
                        callback: () => { },
                    });
                }  
            }

            return Promise.reject(error.response);  
        } else {
            MessageBox.alert(error.message, "出错啦", {
                confirmButtonText: "确定",
                callback: () => { },
            });

            return Promise.reject(error);
        }
    }
);

export default service;
