<template>
  <div class="component-container" flex="main:justify cross:center">
    <div flex="cross:center">
      <img src="../static/logo.png" alt="" class="placeholder-img logo" />
      <span class="logo-text app-mr5">PS游戏管理后台</span>
      <el-button
        class="app-mr5"
        size="mini"
        plain
        type="default"
        @click="$router.push('/position-list')"
        >位置管理</el-button
      >
    </div>
    <div>
      <div>
        <el-dropdown @command="handleCommand">
          <!--                    <span class="el-dropdown-link flex box-center" style="color: #fff">-->
          <!--                        <el-avatar> {{user.username}} </el-avatar><i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
          <!--                    </span>-->
          <div flex="cross:center" class="color-fff">
            <el-avatar :size="50">{{ user.username }}</el-avatar
            ><i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            <!--                    <el-dropdown-item disabled>双皮奶</el-dropdown-item>-->
            <!--                    <el-dropdown-item divided>蚵仔煎</el-dropdown-item>-->
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SysHeader",
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["logout"]),
    handleCommand(cmd) {
      if (cmd == "logout") {
        // 退出登录
        this.mConfirm("确定退出?", () => {
          this.logout().then(() => {
            this.$router.push("/login");
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.component-container {
  padding: 0 20px;
  height: @height-header;
  background-color: @color-primary;
  .logo-text {
    color: #fff;
    font-size: 24px;
    padding-left: 10px;
  }
  .logo {
    height: 50px;
    border-radius: 4px;
  }
  .color-white {
    color: #fff;
  }
  .btn {
    color: @color-warning;
    text-decoration: none;
  }
}
</style>