import { CodeToText, TextToCode } from 'element-china-area-data'

export default {
    // 性别转换
    calcGender(val) {
        return val == 1 ? '男' : '女';
    },
    // 地址转换
    calcAddr(val) {
        if(!val) {
            return '';
        }
        let arr = val.split(',');
        let res = [];
        arr.forEach(v => {
            res.push(CodeToText[v]);
        });
        return res.join(' ');
    }
}