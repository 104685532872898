const options = {
    namespaced: true,
    state() {
        return {
            data: [
                {
                    id: 1,
                    title: '百年孤独',
                    content: '何为百年孤独, 滋味无穷!'
                },
                {
                    id: 2,
                    title: '风烛残年',
                    content: '山穷水尽疑无路, 柳岸花明又一村!'
                },
            ]
        }
    }
};

export default options;