<template>
  <div id="app">
      <div class="layout" flex="dir:top">
        <sys-header v-if="showHeaderAndBar"></sys-header>
        <div class="app-autoflex" flex>
            <side-bar v-if="showHeaderAndBar"></side-bar>
            <div class="app-autoflex">
              <router-view></router-view>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
    import SysHeader from "@/components/SysHeader";
    import SideBar from "@/components/SideBar";
    import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
    components: {
      SysHeader,
      SideBar
  },
  data() {
    return {
      title: 'sys-admin-v0.0.1',
    }
  },
  computed: {
    ...mapState(['user']),
    showHeaderAndBar() {
      return this.user && !this.$route.meta.skipAuth
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less">
  /*iconfont*/
  .iconfont {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  /*iconfont end*/

  #app{
    min-height: calc(var(--vh, 1vh) * 100);
  }
  .layout{
    min-height: calc(var(--vh, 1vh) * 100);
  }
  #nprogress .bar {
    background: @color-success !important;
  }
  // cascader下拉高度
  .el-cascader-menu__wrap {
    height: 200px !important;
  }
  // 统一表单下拉框宽度
  .el-select, .el-cascader{
    width: 250px;
  }
  .el-date-editor.el-input{
    width: 250px !important;
  }
</style>
