let env = process.env.NODE_ENV ? process.env.NODE_ENV : 'development';
let apiVersion = ''; // eg: /v1, /v2, /v3...

let config = {
	development: {
		baseURL: '/api' + apiVersion,
		commonBaseURL: '/api',
		imgBaseUrl: 'http://yii.my',
		uploadBaseUrl: 'http://yii.my',
		debug: true
	},
	production: {
		baseURL: '/api' + apiVersion,
		commonBaseURL: '/api',
		// baseURL: 'http://yii.my/v1',
		// commonBaseURL: 'http://yii.my',
		imgBaseUrl: 'https://psnjilu.leyunyouxi.cn',
		uploadBaseUrl: 'https://psnjilu.leyunyouxi.cn',
		debug: false
	}
}

export default {
	baseURL: config[env]['baseURL'],
	commonBaseURL: config[env]['commonBaseURL'],
	imgBaseUrl: config[env]['imgBaseUrl'],
	uploadBaseUrl: config[env]['uploadBaseUrl'],
	debug: config[env]['debug']
}