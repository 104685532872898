import Vue from "vue";
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

// 自动加载模块配置
const ctx = require.context('./modules', false, /\.js$/)
let configModules = {};
for(let key of ctx.keys()) {
    let field = key.replace(/(\.\/)|(\.js)/g, '');
    configModules[field] = ctx(key).default;
}

const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: configModules,
    plugins: [createPersistedState()]
});

export default store;