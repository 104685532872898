import '@/common/styles/style.css'
import '@/common/styles/flex.css'
import 'nprogress/nprogress.css'
import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'
import router from './routes'
import store from './store'
import './common/plugins/element.js'
import './common/icons/index.js'
import '@/common/utils/index.js'
import mixin from '@/common/mixins'
import './common/filters/index.js'
import '@/api'

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.mixin(mixin);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
