import { render, staticRenderFns } from "./SysHeader.vue?vue&type=template&id=9cbe7e06&scoped=true"
import script from "./SysHeader.vue?vue&type=script&lang=js"
export * from "./SysHeader.vue?vue&type=script&lang=js"
import style0 from "./SysHeader.vue?vue&type=style&index=0&id=9cbe7e06&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cbe7e06",
  null
  
)

export default component.exports