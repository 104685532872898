import Vue from 'vue';
import request from '@/common/utils/request.js'
import config from '@/common/config.js'

// 全局api
let api = {
    // 登录
    login(data) {
        let url = '/auth/login';
        return request.post(url, data, {
            baseURL: config.commonBaseURL
        });
    },
    logout() {
        let url = '/auth/refresh-token';
        return request.delete(url, {
            baseURL: config.commonBaseURL
        });
    },
    // refresh token
    refreshToken() {
        let url = '/auth/refresh-token';
        return request.post(url, {
            baseURL: config.commonBaseURL
        });
    },
    // 获取菜单
    getMenu() {
        let url = '/menu';
        return request.get(url);
    }
}

// 加载模块api
const ctx = require.context('./', false, /\.js$/)
for (let key of ctx.keys()) {
    let field = key.replace(/(\.\/)|(\.js)/g, '');
    api[field] = ctx(key).default;
}

Vue.prototype.$api = api;

export default api;