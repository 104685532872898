import config from '../common/config.js'

const state = () => {
    return {
        uploadBaseUrl: config.uploadBaseUrl,
        isLoading: false,
        disableLoading: false,
        user: null,
        token: null,
        activeMenuIndex: 0,
        activeMenuPath: ['1'],
        activeMenu: [],
        menuOpts: [
            {
                id: 1,
                pid: 0,
                route: '/',
                name: '首页',
                icon: 'home'
            },
            {
                id: 7,
                pid: 0,
                route: '/article-list',
                name: '文章列表',
                icon: 'article'
            },
            {
                id: 2,
                pid: 0,
                route: '',
                name: '订单管理',
                icon: 'order',
                children: [
                    {
                        id: 4,
                        pid: 2,
                        route: '/order-list',
                        name: '订单列表'
                    },
                    {
                        id: 5,
                        pid: 2,
                        route: '',
                        name: '订单医院',
                        children: [
                            {
                                id: 6,
                                pid: 5,
                                route: '/test',
                                name: '华西第二'
                            },
                        ]
                    },
                ]
            },
            {
                id: 3,
                pid: 0,
                route: 'https://www.baidu.com',
                name: '网站设置',
                icon: 'settings',
                type: 1
            },
            {
                id: 4,
                pid: 0,
                route: '/iframe?url=http://www.mikeding.cn&title=miked`s website',
                name: '网站设置',
                icon: 'settings',
            },
        ],
        // user: {
        //     id: 0,
        //     username: '',
        //     thumb: '',
        //     gender: 1,
        //     age: 0
        // }
    }
};

export default state;