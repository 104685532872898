import VueRouter from "vue-router";
import NProgress from 'nprogress';
import store from "../store";
import utils from "@/common/utils/utils.js";

NProgress.configure({ showSpinner: false });

// 自动加载模块配置
const ctx = require.context('./modules', false, /\.js$/)
let configModules = [];
for (let key of ctx.keys()) {
    configModules.push(...ctx(key).default);
}

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/index.vue'),
        meta: {
            title: '统计信息'
        }
    },
    {
        path: '/placeholder',
        name: 'placeholder',
        component: () => import('@/views/placeholder.vue'),
        meta: {
            title: ''
        }
    },
    {
        path: '/iframe',
        name: 'innerIframe',
        component: () => import('@/views/iframe.vue'),
        meta: {
            title: ''
        }
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test.vue'),
        meta: {
            title: '测试'
        },
        children: [
            {
                path: 'nested',
                name: 'test-nested',
                component: () => import('@/views/test-nested.vue'),
                meta: {
                    title: '测试-嵌套'
                }
            }
        ]
    },
    {
        path: '/article',
        name: 'article',
        component: () => import('@/views/article.vue'),
        meta: {
            title: '测试'
        }
    },
    ...configModules
]

// catch 404
routes.push({
    path: '*',
    name: 'page-404',
    component: () => import('@/views/404.vue'),
    meta: {
        title: 'Not Found',
        skipAuth: true
    }
});

const router = new VueRouter({
    routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
    // authentication
    if (!to.meta.skipAuth && !store.state.user) {
        next({ name: 'login' });
    } else {
        NProgress.start();
        if (to.meta.title) {
            document.title = to.meta.title
        }
        // store.commit()

        let indexAndPath = utils.getMenuIndexAndPathByRoute(store.state.menuOpts, decodeURIComponent(to.fullPath));
        if (indexAndPath)
            store.commit('changeMenu', indexAndPath);

        next();
    }
})

router.afterEach(() => {
    NProgress.done();
})

export default router