import { v4 as uuidv4 } from "uuid";
import config from "../config.js";

const debug = config.debug;

export default {
    // 生成随机密码 包含大小写字母数字特殊字符密码长度以及排除字符 genRandomPass(options)
    // usage: genRandomPass({ exclude: 'iIl10oO', useSpecial: false })
    genRandomPass(options) {
        const defaultOptions = {
            useLetterSmall: true, // 小写字母 a-z
            useLetterCapital: true, // 大写字母 A-Z
            useNumber: true, // 数字 0-9
            useSpecial: true, // 特殊字符 ~!@#$%^&*
            exclude: '', // 排除的字符
            count: 8, // 随机密码的长度
        }

        options = options ? Object.assign({}, defaultOptions, options) : defaultOptions;

        let seed = [], letterSmall = [], letterCapital = [], number = [], special = [], pass = '';

        if (options.useLetterSmall) {
            letterSmall = new Array(26).fill(null).map((v, i) => String.fromCharCode(97 + i));
        }

        if (options.useLetterCapital) {
            letterCapital = new Array(26).fill(null).map((v, i) => String.fromCharCode(65 + i));
        }

        if (options.useNumber) {
            number = new Array(10).fill(null).map((v, i) => i + '');
        }

        if (options.useSpecial) {
            special = '~!@#$%^&*'.split('');
        }

        seed = seed.concat(letterSmall, letterCapital, number, special);

        // 排除字符
        if (options.exclude) {
            let excludes = options.exclude.split('');
            excludes.forEach((v, i) => {
                let index = seed.indexOf(v);
                if (index > -1) {
                    seed.splice(index, 1);
                }
            });
        }

        // console.log(seed);

        if (seed.length) {
            for (let i = 0; i < options.count; i++) {
                pass += seed[Math.floor(Math.random() * seed.length)];
            }
        }

        return pass;

    },
    // 复制文本
    copy(text, cb) {
        var element = createTmpElement(text);
        element.select();
        element.setSelectionRange(0, element.value.length);
        document.execCommand('copy');
        element.remove();
        cb();

        //创建临时的输入框元素
        function createTmpElement(text) {
            var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
            var element = document.createElement('textarea');
            // 防止在ios中产生缩放效果
            element.style.fontSize = '12pt';
            // 重置盒模型
            element.style.border = '0';
            element.style.padding = '0';
            element.style.margin = '0';
            // 将元素移到屏幕外
            element.style.position = 'absolute';
            element.style[isRTL ? 'right' : 'left'] = '-9999px';
            // 移动元素到页面底部
            let yPosition = window.pageYOffset || document.documentElement.scrollTop;
            element.style.top = yPosition + 'px';
            //设置元素只读
            element.setAttribute('readonly', '');
            element.value = text;
            document.body.appendChild(element);
            return element;
        }
    },
    /**
     * 
     * @param {*} keyPath 形如 a.b.c
     * @param {*} jsonData { a: { b: { c: 'target value' } } } 
     * @returns 
     */
    getValueByKeyPath(keyPath, jsonData) {
        return keyPath.split('.').reduce((computed, v) => {
            if (computed) return computed[v]
        }, jsonData)
    },
    canvas2Image(canvas, width, height) {
        const retCanvas = document.createElement('canvas');
        const retCtx = retCanvas.getContext('2d');
        retCanvas.width = width;
        retCanvas.height = height;
        retCtx.drawImage(canvas, 0, 0, width, height, 0, 0, width, height);
        const img = document.createElement('img');
        img.src = retCanvas.toDataURL('image/jpeg');  // 可以根据需要更改格式
        return img;
    },
    // 鼠标上下滚动
    onMousewheel(dom, upCb, downCb) {
        let callback = function (event) {
            event = event || window.event;
            // event.wheelDelta 可以获取鼠标滚轮滚动的方向
            // 向上滚120    向下滚-120
            // wheelDelta这个值我们不看大小，只看正负

            // wheelDelta这个属性火狐不支持
            // 在火狐中使用event.detail来获取滚动的方向
            // 向下滚-3 向上滚3

            // 判断鼠标滚动方向
            if (event.wheelDelta > 0 || event.detail < 0) {
                // 向上滚
                upCb();
            } else {
                // 向下滚
                downCb();
            }

            // 使用addEventListener()方法绑定响应函数时，取消默认行为时不能使用return false
            // 需要使用event来取消默认行为event.preventDefault()
            // 但是IE8不支持event.preventDefault()
            event.preventDefault && event.preventDefault();

            // 当滚轮滚动时，如果浏览器有滚动条时，滚动条会随之滚动
            // 这是浏览器的默认行为，如果不希望发生，则可以取消默认行为
            return false;
        };

        this.bindEvent(dom, ['mousewheel', 'DOMMouseScroll'], [callback, callback]);
    },
    // 绑定DOM事件
    bindEvent(obj, events, callbacks) {
        if (Array.isArray(events)) { // 数组绑定多个事件
            events.forEach((v, i) => {
                if (obj.addEventListener) {
                    obj.addEventListener(v, callbacks[i], false);
                } else {
                    obj.attachEvent("on" + v, function () {
                        callbacks[i].call(obj);
                    });
                }
            });
        } else { // 绑定单个事件
            if (obj.addEventListener) {
                obj.addEventListener(events, callbacks, false);
            } else {
                obj.attachEvent("on" + events, function () {
                    callbacks.call(obj);
                });
            }
        }
    },
    getDateTime(timestamp, dateSplitStr, gapStr, timeSpliteStr) {
        if (dateSplitStr === undefined) dateSplitStr = '-';
        if (gapStr === undefined) gapStr = ' ';
        if (timeSpliteStr === undefined) timeSpliteStr = ':';
        var dateStr;
        var timeStr;
        if (timestamp) {
            timestamp = ((timestamp + '').length > 10) ? timestamp : (timestamp * 1000);
            dateStr = [new Date(timestamp).getFullYear(), new Date(timestamp).getMonth() + 1, new Date(timestamp).getDate()];
            timeStr = [new Date(timestamp).getHours(), new Date(timestamp).getMinutes(), new Date(timestamp).getSeconds()];
        } else {
            dateStr = [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()];
            timeStr = [new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()];
        }
        dateStr = dateStr.map(function (v, i) {
            if (v < 10) {
                v = '0' + v;
            }
            return v;
        });
        timeStr = timeStr.map(function (v, i) {
            if (v < 10) {
                v = '0' + v;
            }
            return v;
        });
        dateStr = dateStr.join(dateSplitStr);
        timeStr = timeStr.join(timeSpliteStr);
        return dateStr + gapStr + timeStr;
    },
    // 构造层级结构的数据 list 源数据数组 children 子级属性 id 主键 pid 父级关连键
    makeHierarchy(list, level = 0, children = 'children', id = 'id', pid = 'pid') {
        let newList = [];
        let remainList = [];
        list.forEach(v => {
            if (v[pid] == level) {
                newList.push(v);
            } else {
                remainList.push(v);
            }
        })

        newList.forEach(v => {
            v[children] = this.makeHierarchy(remainList, v[id]);
            if (v[children].length == 0) {
                v[children] = null;
            }
        })

        return newList;
    },
    makeDebounce(func, wait, immediate) {
        let timeout, args, context, timestamp, result
        if (!wait) wait = 150;  // 默认间隔150ms

        const later = function () {
            // 据上一次触发时间间隔
            const last = +new Date() - timestamp

            // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last)
            } else {
                timeout = null
                // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
                if (!immediate) {
                    result = func.apply(context, args)
                    if (!timeout) context = args = null
                }
            }
        }

        return function (...argsArr) {
            context = this
            args = argsArr
            timestamp = +new Date()
            const callNow = immediate && !timeout
            // 如果延时不存在，重新设定延时
            if (!timeout) {
                args = argsArr
                timeout = setTimeout(later, wait)
            }
            if (callNow) {
                result = func.apply(context, args)
                context = args = null
            }

            return result
        }
    },
    setStorage(key, data, type = 'local') {
        key = '$store--' + key;
        if (typeof data != 'string') {
            data = JSON.stringify(data);
        }
        if (type === 'local') {
            window.localStorage.setItem(key, data);
        } else {
            window.sessionStorage.setItem(key, data);
        }
    },
    getStorage(key, type = 'local') {
        key = '$store--' + key;
        let ret;
        if (type === 'local') {
            ret = window.localStorage.getItem(key);
        } else {
            ret = window.sessionStorage.getItem(key);
        }

        if (/^\[.*\]$|^\{.*\}$|^true$|^false$/.test(ret)) {
            return JSON.parse(ret);
        }
        return ret;
    },
    removeStorage(key, type = 'local') {
        key = '$store--' + key;
        if (type === 'local') {
            window.localStorage.removeItem(key);
        } else {
            window.sessionStorage.removeItem(key);
        }
    },
    simpleCopy(data) {
        return JSON.parse(JSON.stringify(data));
    },
    isInArrayByKey(keyValue, arr, field = "_id") {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][field] == keyValue) {
                return true;
            }
        }

        return false;
    },
    /**
     *  获取安全的属性值
     * @param item 源对象
     * @param attr [string] 形如'a.b.c' 默认为空
     * @param defaultValue 未找到返回的默认值 默认为空
     * @param prefix 前缀
     */
    getSafeAttr(item, attr = "", defaultValue = "", prefix = "", suffix = "") {
        if (
            Object.prototype.toString.call(item).toLowerCase() !==
            "[object object]"
        ) {
            return "";
        }
        if (!attr || /^\s+$/.test(attr)) return "";

        attr = attr.replace(/\s/g, "");
        let attrArr = attr.split(".");
        for (let i = 0; i < attrArr.length; i++) {
            if (i == attrArr.length - 1) {
                let realReturn = item[attrArr[i]];
                if (prefix) realReturn = prefix + realReturn;
                if (suffix) realReturn = realReturn + suffix;
                return item[attrArr[i]] ? realReturn : defaultValue;
            } else {
                if (!item[attrArr[i]]) return defaultValue;
                item = item[attrArr[i]];
            }
        }
    },
    isGoToContainerEnd(container, content, offset) {
        if (!offset) offset = 50;
        if (!content) content = window;
        if (!container) container = document.documentElement;

        var scrollTop = container.scrollTop; //页面卷去的高度
        var wh = container.clientHeight;
        var passHeight = scrollTop + wh;
        var htmlHeight = container.scrollHeight - offset;

        if (wh > container.scrollHeight) return true;
        if (passHeight > htmlHeight) {
            return true;
        }
        return false;
    },
    getArrsIntersect(arrs) {
        var results = arrs[0];
        for (var i = 1; i < arrs.length; i++) {
            results = results.filter((v) => {
                return arrs[i].includes(v);
            });
            if (results.length === 0) return null;
        }

        return results;
    },
    // 获取星期
    getDay(nowDay, plusDayNumber, useText) {
        if (!nowDay) nowDay = new Date().getDay();
        if (!plusDayNumber) plusDayNumber = 0;
        var resDay = (nowDay + plusDayNumber) % 7;
        if (useText) {
            switch (resDay) {
                case 0:
                    return "星期日";
                case 1:
                    return "星期一";
                case 2:
                    return "星期二";
                case 3:
                    return "星期三";
                case 4:
                    return "星期四";
                case 5:
                    return "星期五";
                case 6:
                    return "星期六";
            }
        }
        return resDay;
    },
    // 获取星期数组
    getDayArr(nowDay, dayLen, useText) {
        var arr = [];

        for (var i = 0; i < dayLen; i++) {
            arr.push(this.getDay(nowDay, i, useText));
        }

        return arr;
    },
    // 获取日期 timestamp 毫秒
    getDate(timestamp, dateSplitStr) {
        if (dateSplitStr === undefined) dateSplitStr = "-";
        var dateStr;
        if (timestamp) {
            dateStr = [
                new Date(timestamp).getFullYear(),
                new Date(timestamp).getMonth() + 1,
                new Date(timestamp).getDate(),
            ];
        } else {
            dateStr = [
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                new Date().getDate(),
            ];
        }
        dateStr = dateStr.map(function (v, i) {
            if (v < 10) {
                v = "0" + v;
            }
            return v;
        });
        dateStr = dateStr.join(dateSplitStr);
        return dateStr;
    },
    // 获取日期数组
    getDateArr(timestamp, dayLen) {
        if (!timestamp) timestamp = new Date().getTime();
        var arr = [];
        for (var i = 0; i < dayLen; i++) {
            arr.push(this.getDate(timestamp + i * (24 * 3600 * 1000)));
        }
        return arr;
    },
    // AES加密
    encrypt(data, key = "kgfjsoeoefasdfll") {
        key = CryptoJS.enc.Utf8.parse(key);
        let encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: "",
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    },
    // AES解密
    decrypt(encData, key = "kgfjsoeoefasdfll") {
        key = CryptoJS.enc.Utf8.parse(key);
        let decrypted = CryptoJS.AES.decrypt(encData, key, {
            iv: "",
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        // 转换为 utf8 字符串
        decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
        return JSON.parse(decrypted.toString());
    },
    // 3DES加密
    encryptBy3DES(message, key) {
        var keyHex = CryptoJS.enc.Utf8.parse(key);

        var encrypted = CryptoJS.TripleDES.encrypt(message, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).ciphertext;

        encrypted = CryptoJS.enc.Base64.stringify(encrypted);

        return encrypted;
    },
    // 3DES解密
    decryptBy3DES(ciphertext, key) {
        var keyHex = CryptoJS.enc.Utf8.parse(key);

        var decrypted = CryptoJS.TripleDES.decrypt(
            {
                ciphertext: CryptoJS.enc.Base64.parse(ciphertext),
            },
            keyHex,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        );

        return decrypted.toString(CryptoJS.enc.Utf8);
    },
    // 在一段时间内是否闲置 params: 时间(秒) 闲置回调 非闲置回调 监控的事件
    idleInTime(seconds, yesCallBack, noCallBack, events) {
        let eventCache = [];
        let eventCallBacks = [];

        if (!events) {
            events = ["click", "mousemove", "keydown"];
        }

        // 绑定事件
        events.forEach((v) => {
            let fn = () => {
                eventCache.push(v);
                console.log(v);
            };
            eventCallBacks.push(fn);
            document.addEventListener(v, fn);
        });

        let timer = setTimeout(function () {
            if (eventCache.length == 0) {
                yesCallBack();
            } else {
                noCallBack();
            }
            // 解绑事件
            events.forEach((v, i) => {
                document.removeEventListener(v, eventCallBacks[i]);
            });
        }, seconds * 1000);
    },
    // 动态加载js
    dynamicLoadScript(scriptSrc) {
        var oHead = document.getElementsByTagName("head")[0]; // 在head标签中创建创建script
        var oScript = document.createElement("script");
        oScript.type = "text/javascript";
        oScript.src = scriptSrc;
        oHead.appendChild(oScript);
    },
    // 获取给定表单的光标位置
    getCursorPos(element) {
        let cursorPos = 0;

        if (document.selection) {
            // IE
            var selectRange = document.selection.createRange();
            selectRange.moveStart("character", -element.value.length);
            cursorPos = selectRange.text.length;
        } else if (element.selectionStart || element.selectionStart == "0") {
            cursorPos = element.selectionStart;
        }

        return cursorPos;
    },
    genUUID() {
        return uuidv4();
    },
    // encode url参数
    encodeParamValue(data) {
        if (typeof data == "string") {
            return encodeURIComponent(data);
        } else {
            return encodeURIComponent(JSON.stringify(data));
        }
    },
    // 根据route路由找到菜单的index indexPath
    getMenuIndexAndPathByRoute(list, route, alreadyPath = []) {
        for (let i = 0; i < list.length; i++) {
            let index = 0;
            let indexPath = Array.from(alreadyPath);
            if (list[i]["route"] === route) {
                index = list[i]["id"] + "";
                indexPath.push(index);

                return {
                    index,
                    indexPath,
                };
            } else {
                if (list[i]["children"]) {
                    indexPath.push(list[i]["id"] + "");
                    let next = this.getMenuIndexAndPathByRoute(
                        list[i]["children"],
                        route,
                        indexPath
                    );
                    if (next) return next;
                }
            }
        }
    },
    /**
     *
     * @param { 源数据 } list
     * @param { 路径数组 如 ['1', '12', '121'] } path
     * @returns 按路径顺序排列的一维数组
     */
    getObjArrByPath(list, path) {
        let tmpArr = [];
        let realPath = [];
        for (let i = 0; i < path.length; i++) {
            realPath.push(path[i]);
            tmpArr.push(this.getObjByPath(list, realPath));
        }
        return tmpArr;
    },
    /**
     *  根据path返回指定的对象
     * @param path Array|String ['1', '12', '121'] | '1'
     */
    getObjByPath(list, path, options = { key: "id", subList: "children" }) {
        if (!Array.isArray(path)) {
            return this.getObjByField(list, path, options.key);
        }

        for (let i = 0; i < path.length; i++) {
            if (i < path.length - 1) {
                let tmpObj = this.getObjByField(list, path[i], options.key);
                list = tmpObj[options.subList];
            } else {
                return this.getObjByField(list, path[i], options.key);
            }
        }
    },
    /**
     * 根据键返回对象, key默认为id
     * @param list
     * @param keyValue
     * @param key
     * @returns {null|*}
     */
    getObjByField(list, keyValue, key = "id") {
        for (let i = 0; i < list.length; i++) {
            if (list[i][key] == keyValue) {
                return list[i];
            }
        }

        return null;
    },
    log() {
        if (debug) {
            console.log(...arguments);
        }
    },
    logJson(data) {
        if (debug) {
            console.log(JSON.stringify(data));
        }
    },
    getSearchAndHashObj() {
        var search = location.search;
        var hash = location.hash;
        var res = {};

        if (search) {
            search = search.substr(search.indexOf('?') + 1);
            var tmpArr = search.split('&');
            for (var i = 0; i < tmpArr.length; i++) {
                var tmpArr2 = tmpArr[i].split('=');
                res[tmpArr2[0]] = tmpArr2[1];
            }
        }
        if (hash && hash.indexOf('?') > -1) {
            hash = hash.substr(hash.indexOf('?') + 1);
            var tmpArr = hash.split('&');
            for (var i = 0; i < tmpArr.length; i++) {
                var tmpArr2 = tmpArr[i].split('=');
                res[tmpArr2[0]] = tmpArr2[1];
            }
        }

        return res;

    },
};
