import utils from '../common/utils/utils';

const mutations = {
    login(state, payload) {
        state.user = payload.user;
        state.token = payload.token;
    },
    logout(state) {
        state.user = null;
        state.token = null;
    },
    refreshToken(state, token) {
        state.token = token;
    },
    resetToken(state) {
        state.token = null;
    },
    changeMenu(state, payload) {
        state.activeMenuIndex = payload.index + '';
        state.activeMenuPath = payload.indexPath;
        state.activeMenu = utils.getObjArrByPath(state.menuOpts, state.activeMenuPath);
    },
    // 切换loading状态
    setLoading(state, payload) {
        state.isLoading = payload;
    },
    // 禁用loading
    disableLoading(state) {
        state.isLoadingDisabled = true;
    },
    // 启用loading
    enableLoading(state) {
        state.isLoadingDisabled = false;
    },
    // 设置菜单
    setMenu(state, menus) {
        state.menuOpts = menus;
    }
}

export default mutations;