import { CodeToText, TextToCode } from 'element-china-area-data'
import config from '../config.js'

export default {
    data() {
        return {
            mImgBaseUrl: config.imgBaseUrl,
            mbaseURL: config.baseURL,
            mCommonBaseURL: config.commonBaseURL,
        }
    },
    methods: {
        mGenTableIndex(idx) {
            return (this.pagination.page - 1) * this.pagination.pageSize + (idx + 1)
        },
        /**
         * toast轻提示
         * @param { 提示消息 } msg 
         * @param { success, error, warning, info } type 
         */
        mToast(msg, type = 'info', callback) {
            this.$message({
                message: msg,
                type: type,
                onClose: callback
            });
        },
        mAlert(msg, ok) {
            this.$alert(msg, '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        ok && ok();
                    }
                }
            });
        },
        mConfirm(msg, ok, fail) {
            this.$confirm(msg, '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // type: 'warning'
            }).then(() => {
                ok && ok();
            }).catch(() => {
                fail && fail();
            });
        },
        // 省市区编码与文字转换 参见: element-china-area-data
        mText2Code(arr) {
            let tmpArr = [];
            arr.forEach(v => {
                tmpArr.push(TextToCode[v]);
            });
            return tmpArr;
        },
        mCode2Text(arr) {
            let tmpArr = [];
            arr.forEach(v => {
                tmpArr.push(CodeToText[v]);
            });
            return tmpArr;
        }
    }
}