<template>
  <div class="component-container">
    <div class="switch" :style="!expandMenu ? 'text-align:center' : ''">
      <el-switch v-model="expandMenu"> </el-switch>
    </div>
    <el-menu
      class="side-bar-menu"
      @select="selectMenu"
      :default-active="activeMenuIndex + ''"
      :default-openeds="activeMenuPath"
      :collapse="!expandMenu"
    >
      <nested-menu-item
        :item="item"
        v-for="(item, index) in menuOpts"
        :key="index"
      ></nested-menu-item>
    </el-menu>
  </div>
</template>

<script>
import NestedMenuItem from "./NestedMenuItem";
import { mapState, mapMutations } from "vuex";

export default {
  name: "SideBar",
  components: {
    NestedMenuItem,
  },
  data() {
    return {
      expandMenu: true,
      // data: []
    };
  },
  computed: {
    ...mapState(["activeMenuIndex", "activeMenuPath", "menuOpts"]),
  },
  mounted() {},
  methods: {
    ...mapMutations(["changeMenu"]),
    selectMenu(index, indexPath) {
      let menu = this.$utils.getObjByPath(this.menuOpts, indexPath);
      if (menu.type == 1) {
        // 外链
        window.open(menu.route);
        this.$router.push("/placeholder");

        // location.reload();

        // this.changeMenu({
        //     index: 1,
        //     indexPath: ['1']
        // });

        return false;
      }
      if (this.$route.path != menu.route) {
        this.$router.push(menu.route);
      }
    },
  },
};
</script>

<style scoped lang="less">
.component-container {
  height: calc(100vh - @height-header);
  display: flex;
  flex-direction: column;
  border-right: @border-color-1 solid 1px;
  .switch {
    padding: 10px;
    text-align: right;
    border-bottom: @border-color-1 solid 1px;
  }
  .el-menu {
    overflow-y: auto;
    flex: 1;
    &:not(.el-menu--collapse) {
      width: 300px;
    }
    border-right: none !important;
    &.el-menu--collapse > li {
      text-align: center;
    }
  }
}
</style>
<style lang="less">
// not scoped
.side-bar-menu.el-menu.el-menu--collapse .icon {
  margin-right: 0;
}
</style>