import api from '@/api'
import utils from '@/common/utils/utils.js'

const actions = {
    refreshToken({ commit }) {
        return new Promise((resolve, reject) => {
            api.refreshToken().then(res => {
                if(res.code == 0) {
                    commit('refreshToken', res.data.token);
                    resolve(res);
                } else {
                    commit('resetToken');
                    reject(res);
                }
            })
        });
    },
    login({ commit }, formData) {
        return new Promise(resolve => {
            api.login(formData).then(res => {
                if(res.code == 0)
                    commit('login', res.data);
                resolve(res);
            })
        });
    },
    logout({ commit }) {
        return new Promise(resolve => {
            api.logout().then(res => {
                commit('logout');
                resolve(res);
            })
        })
    },
    // 获取菜单
    getMenu({ commit }) {
        return new Promise(resolve => {
            api.getMenu().then(res => {
                let menusPrev = res.items;
                let menus = utils.makeHierarchy(menusPrev);
                commit('setMenu', menus);
                resolve(res);
            });
        });

        // eg: 
        setTimeout(() => {
            let menus = [
                {
                    id: 1,
                    pid: 0,
                    route: '/',
                    name: '首页',
                    icon: 'home'
                },
                {
                    id: 7,
                    pid: 0,
                    route: '/order-list',
                    name: '订单列表',
                    icon: 'order'
                },
                {
                    id: 2,
                    pid: 0,
                    route: '',
                    name: '文章管理',
                    icon: 'article',
                    children: [
                        {
                            id: 4,
                            pid: 2,
                            route: '/article-list',
                            name: '文章列表'
                        },
                        {
                            id: 5,
                            pid: 2,
                            route: '',
                            name: '订单医院',
                            children: [
                                {
                                    id: 6,
                                    pid: 5,
                                    route: '/test',
                                    name: '华西第二'
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 3,
                    pid: 0,
                    route: 'https://www.baidu.com',
                    name: '网站设置',
                    icon: 'settings',
                    type: 1
                },
                {
                    id: 4,
                    pid: 0,
                    route: '/iframe?url=http://www.mikeding.cn&title=miked`s website',
                    name: '网站设置',
                    icon: 'settings',
                },
            ];
            
            commit('setMenu', menus);
        }, 500);
    }
};

export default actions;