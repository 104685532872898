const getters = {
    genderText(state) {
        if(state.user) {
            return state.user.gender == 1 ? '男' : '女';
        }
        return '';
    },
    getApiToken(state) {
        if(state.token) {
            return "Bearer " + state.token;
        }
        
        return null;
    },
};

export default getters;