export default [
    {
        path: '/game-list',
        name: 'game-list',
        component: () => import('@/views/game-list.vue'),
        meta: {
            title: '游戏列表'
        }
    },
    {
        path: '/account-list',
        name: 'account-list',
        component: () => import('@/views/account-list.vue'),
        meta: {
            title: '账号列表'
        }
    },
    {
        path: '/account-list-by-id',
        name: 'account-list-by-id',
        component: () => import('@/views/account-list-by-id.vue'),
        meta: {
            title: '账号列表'
        }
    },
    {
        path: '/position-list',
        name: 'position-list',
        component: () => import('@/views/position-list.vue'),
        meta: {
            title: '位置列表'
        }
    },
    {
        path: '/position-list-by-id',
        name: 'position-list-by-id',
        component: () => import('@/views/position-list-by-id.vue'),
        meta: {
            title: '位置列表'
        }
    },
    {
        path: '/misc-list',
        name: 'misc-list',
        component: () => import('@/views/misc-list.vue'),
        meta: {
            title: '杂项列表'
        }
    },
    {
        path: '/order-list',
        name: 'order-list',
        component: () => import('@/views/order-list.vue'),
        meta: {
            title: '订单列表'
        }
    },
    {
        path: '/order-pre-list',
        name: 'order-pre-list',
        component: () => import('@/views/order-pre-list.vue'),
        meta: {
            title: '预订单列表'
        }
    },
    {
        path: '/order-pre-list-by-id',
        name: 'order-pre-list-by-id',
        component: () => import('@/views/order-pre-list-by-id.vue'),
        meta: {
            title: '预订单列表'
        }
    },
    {
        path: '/vip-list',
        name: 'vip-list',
        component: () => import('@/views/vip-list.vue'),
        meta: {
            title: '会员管理'
        }
    },

]