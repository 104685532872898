<template>
    <svg class="icon" aria-hidden="true" :style="style">
        <use :xlink:href="realName"></use>
    </svg>
</template>

<script>
    export default {
        name: "SvgIcon",
        computed: {
            realName() {
                return '#icon-' + this.name
            },
            style() {
                if(this.top) {
                    return {
                        top: this.top
                    }
                } else {
                    return {}
                }
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            top: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style scoped lang="less">
    .icon {
        position: relative;
        width: 1em;
        height: 1em;
        // vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
</style>