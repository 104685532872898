<template>
    <el-menu-item v-if="!item.children && item.pid == 0" :index="item.id + ''">
        <svg-icon :name="item.icon" class="app-mr5"></svg-icon><span slot="title">{{item.name}}</span>
    </el-menu-item>
    <el-menu-item v-else-if="!item.children" :index="item.id + ''">{{item.name}}</el-menu-item>
    <el-submenu v-else :index="item.id + ''">
        <template slot="title" v-if="item.pid != 0">{{item.name}}</template>
        <template slot="title" v-else>
            <svg-icon :name="item.icon" class="app-mr5"></svg-icon><span slot="title">{{item.name}}</span>
        </template>
        <nested-menu-item :item="subItem" v-for="(subItem,index) in item.children" :key="index"></nested-menu-item>
    </el-submenu>
</template>

<script>
    export default {
        name: "NestedMenuItem",
        props: {
            item: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped lang="less">

</style>